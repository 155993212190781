import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import type { Maybe } from '@/graphql-schema';

import { Localizations } from '@/constants';

const useLocalization = () => {
  const navigate = useNavigate();
  const { localization } = useParams();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  const isEnSelected = localization === Localizations.EN;
  const isUkSelected = localization === Localizations.UK;
  const isInvalidLanguage = !isEnSelected && !isUkSelected;
  const currentLanguage = !isInvalidLanguage ? localization : Localizations.UK;
  const currentPath = useMemo(
    () => pathname.split(`/${localization}` ?? '')[1],
    [pathname, localization],
  );

  useEffect(() => {
    if (localization) {
      if (isInvalidLanguage) {
        changeLangInUrl(currentLanguage)();
      }
      i18n.changeLanguage(currentLanguage);
    }
  }, [localization]);

  const navigateWithLang = (url: string) => {
    navigate(`/${localization}${url}`);
  };

  const openNewWindow = (url: string) => {
    window.open(`/${localization}${url}`);
  };

  const changeLangInUrl = (lang: Localizations) => () => {
    const path = pathname.replace(/^\/[^/]+/, '');

    navigate(`/${lang}${path}`, { replace: true });
  };

  const transform = (catName?: Maybe<string>) => {
    if (catName) {
      const splitted = catName.split('|');
      if (splitted.length <= 1) {
        return catName;
      }
      const [en, ...params] = splitted;
      const uk = params.pop();

      if (isEnSelected) return en;
      if (isUkSelected) return uk;

      return catName;
    }
  };

  return {
    t,
    currentPath,
    localization,
    isEnSelected,
    isUkSelected,
    openNewWindow,
    navigate: navigateWithLang,
    changeLangInUrl,
    transform,
  };
};

export default useLocalization;
