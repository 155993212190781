import { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorCodes } from '@/constants';
import { useAudioPlayer, useKeycloak } from '@/context';
import { GET_GIFT_BOX_CONTENTS } from '@/graphqL-queries/contents';
import { POST_GIFT_CONTENT_ACCEPT } from '@/graphqL-queries/purchase';
import { ComplexTypeEnum, FaultPayload } from '@/graphql-schema';
import { getComplexContentLabel } from '@/helpers';
import { useGraphQlErrorHandler } from '@/hooks';
import { acceptGiftModalSelector } from '@/redux/accept-gift-modal/selectors';
import { closeAcceptGiftModal } from '@/redux/accept-gift-modal/slice';
import { closeErrorModal, openErrorModal } from '@/redux/error-modal/slice';

export const useAcceptGiftModal = () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { msisdn } = useKeycloak();
  const { stopPlaying } = useAudioPlayer();
  const { isModalOpen: isOpen, contentItem } = useSelector(acceptGiftModalSelector);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const [accept, { loading, error }] = useMutation(POST_GIFT_CONTENT_ACCEPT);

  const processResponse = (data: FaultPayload) => {
    if (!data?.error) return;
    setErrorCode(data?.error?.code || null);
    dispatch(openErrorModal());
  };

  const isComplex = contentItem?.complexType === ComplexTypeEnum.Package;
  const submitText = t('componentPresentCard.acceptModal.accept');
  const cancelText = t('componentPresentCard.acceptModal.cancel');

  const complexType = contentItem?.complexType;

  const contentType = useMemo(
    () => (complexType ? getComplexContentLabel(complexType).toLowerCase().trim() : null),
    [language, complexType],
  );

  const description = isComplex
    ? t('componentPresentCard.acceptModal.descriptionComplexContent', {
        ...({ ...contentItem, title: contentItem?.title?.trim() } ?? {}),
        contentType,
      })
    : t('componentPresentCard.acceptModal.description', {
        ...({ ...contentItem, title: contentItem?.title?.trim() } ?? {}),
        contentType,
      });

  useGraphQlErrorHandler(error);

  const onClose = () => dispatch(closeAcceptGiftModal());

  const onCloseErrorModal = useCallback(() => {
    dispatch(closeErrorModal());
    setErrorCode(null);
  }, []);

  const onSubmit = () => {
    accept({
      variables: {
        input: {
          contentNo: contentItem?.contentNo,
          subsIdent: msisdn,
        },
      },
      onCompleted: (data) => {
        processResponse(data?.giftContentAccept);
        stopPlaying();
        onClose();
      },
      refetchQueries: [
        {
          query: GET_GIFT_BOX_CONTENTS,
          variables: {
            msisdn,
          },
        },
      ],
    }).catch((err) => {
      const errorCode = err?.graphQLErrors?.[0]?.extensions?.code;
      const isNotRbtError = errorCode === ErrorCodes.NO_RBT_AUTHORIZED;
      if (isNotRbtError) {
        onClose();
      }
    });
  };

  return {
    isOpen,
    loading,
    errorCode,
    submitText,
    cancelText,
    description,
    onClose,
    onSubmit,
    onCancel: onClose,
    onCloseErrorModal,
  };
};
